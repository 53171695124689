@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Favela-r';
    src: url('./Favela/Fontspring-DEMO-favela-bold.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  