.button {
    position: relative;
    display: block;
    width: 10em; /* Uguale alla tua variabile $width */
    height: 2.8em; /* Uguale alla tua variabile $height */
    color: #000000; /* $button-color */
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    transition: color 0.1s ease-in-out; /* Durata e curva di transizione migliorata */
  }
  
  .button::before {
    content: '';
    position: absolute;
    border-radius: 50%;
    display: block;
    width: 20em; /* 2 * $width */
    height: 20em; /* 2 * $width */
    left: -5em; /* -$width / 2 */
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    transition: box-shadow 0.6s cubic-bezier(0.4, 0, 0.2, 1); /* Curva di transizione migliorata e durata più lunga */
    z-index: -1;
  }
  
  .button:hover {
    color: #fff;
  }
  
  .button:hover::before {
    box-shadow: inset 0 0 0 10em rgb(0, 0, 0); /* Inserisce l'ombra interna all'hover */
  }
  